import React from "react"
import { images } from "../../../static/index"
import ServicesHeroSection from "./ServicesHeroSection"
import "./Services.scss"
import DualColContent from "./DualColContent"
import Head from "../utils/Head"

const DileveryProcess = React.lazy(() => import("./DeliveryProcess"))
const QuickProject = React.lazy(() => import("./QuickProject"))
const Tech = React.lazy(() => import("./Tech"))
const PriceServices = React.lazy(() => import("./PriceServices"))
const ServiceAccordian = React.lazy(() => import("./ServicesAcordian"))

function Services() {
  return (
    <div className="services-box overflow-hidden">
      <Head
        metaTitle="Software Development & Consultancy Services - CodeFulcrum"
        title="Data Engineering Solutions & Custom Development- CodeFulcrum"
        desc="Drive innovation with our expert custom software and data engineering services. From development to solutioning, we've got the expertise you need"
      />

      <ServicesHeroSection
        btnText="LET’S DISCUSS HOW"
        img={images.services.ServicesHero}
        scroll="#dualcontent"
        text="Whether you’re a Startup, SME, or an Enterprise - we will create a dedicated solution for you based on the analysis of your business and the latest technologies."
      ></ServicesHeroSection>

      <DualColContent />
      <React.Suspense fallback={<div />}>
        <DileveryProcess />
      </React.Suspense>
      <React.Suspense fallback={<div />}>
        <QuickProject />
      </React.Suspense>

      <div>
        <React.Suspense fallback={<div />}>
          <Tech />
        </React.Suspense>
        <React.Suspense fallback={<div />}>
          <PriceServices />
        </React.Suspense>
      </div>
      <div className="contact-accordian-bg">
        <React.Suspense fallback={<div />}>
          <ServiceAccordian />
        </React.Suspense>
      </div>
    </div>
  )
}
export default Services
