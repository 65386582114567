import React, { useState, useEffect } from "react"
import Accordion from "react-bootstrap/Accordion"
import "./Accordian.scss"
import { Arrow } from "../SVGs"
import { navigate } from "gatsby"

export default function SimpleAccordion({ data, url, index }) {
  const [activeKey, setActiveKey] = useState("0")

  useEffect(() => {
    if (index === 0) {
      setActiveKey("0") // Assuming the first item has key "0"
    }
  }, [index])

  const handleToggle = key => {
    setActiveKey(activeKey === key ? null : key)
  }

  return (
    <div className="services-accordians" style={{ width: "100%" }}>
      <Accordion activeKey={activeKey} className="custom-accordion">
        {data.map((item, i) => (
          <Accordion.Item
            eventKey={`${i}`}
            key={item.id}
            className={`accordian-2 ${
              activeKey === `${i}` ? "on-active-bg" : ""
            }`}
            onClick={() => handleToggle(`${i}`)}
          >
            <Accordion.Header>
              <ul className="ul">
                <li key={i}>{item.header}</li>
              </ul>
            </Accordion.Header>
            <Accordion.Body
              className={`detail ${activeKey === `${i}` ? "ul-active" : ""}`}
            >
              {item.text}
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>

      <div className="div-bar-p-2" onClick={() => navigate(url)}>
        <p>Learn More</p> &nbsp;
        <span>
          <Arrow />
        </span>
      </div>
    </div>
  )
}
